import './simpleHome.scss'
import { TWallpaper } from '@twallpaper/react'
import '@twallpaper/react/css'

const SimpleHome = () => {
  return (
    <>
      <div className="wrapper">
        <dt className="h1">Zora Zhang</dt>
        <dt>Software Engineer at Databricks</dt>
        <dt>Studied Computer Science at UIUC</dt>
      </div>
      <TWallpaper
        options={{
          fps: 20,
          tails: 90,
          animate: true,
          scrollAnimate: true,
          colors: ['#E3E9E5', '#f0f0f0', '#E3E9E5', '#f0f0f0'],
        }}
        style={{ zIndex: -1 }}
      />
    </>
  )
}

export default SimpleHome
